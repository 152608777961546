import {
  FormControl as ChakraFormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
} from "@chakra-ui/react";
import { useFormikContext } from "formik";
import objectPath from "object-path";
import { useMemo } from "react";

export interface FormControlProps {
  isRequired?: boolean;
  isDisabled?: boolean;
  id?: string;
  name: string;
  label?: string;
  helperText?: string;
  hideErrorMessage?: boolean;
  children: React.ReactNode;
}

export function FormControl<Values>({
  isRequired = false,
  isDisabled = false,
  hideErrorMessage = false,
  id,
  name,
  label,
  helperText,
  children,
}: FormControlProps) {
  const { isSubmitting, errors, touched } = useFormikContext<Values>();
  const fieldError = useMemo(() => {
    const error = objectPath.get(errors, name);
    if (typeof error === "string") {
      return error;
    } else {
      return undefined;
    }
  }, [errors, name]);
  const fieldTouched = useMemo(
    () => objectPath.get(touched, name),
    [touched, name]
  );

  return (
    <ChakraFormControl
      isRequired={isRequired}
      isDisabled={isSubmitting || isDisabled}
      isInvalid={!!fieldError && !!fieldTouched}
    >
      {label && <FormLabel htmlFor={id}>{label}</FormLabel>}
      {children}
      {!hideErrorMessage && <FormErrorMessage>{fieldError}</FormErrorMessage>}
      {helperText && (
        <FormHelperText color="fg.subtle">{helperText}</FormHelperText>
      )}
    </ChakraFormControl>
  );
}
